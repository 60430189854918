import {
  Box,
  Card,
  CardContent,
  Divider,
  Typography
} from "@mui/material";
import React from "react";
import AssumptionsAndCriteria from "../assumption-criteria";
import PricingSection from "../pricing-section";
import ScopeOfWorkSection from "../scope-work";
import PremiumTimeline from "../time-line";

const Clientquote = ({ quote, id }) => {
  return (
    <Card
      id={id}
      sx={{
        margin: 2,
        padding: 3,
        borderRadius: 2,
        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#f9f9f9",
      }}
    >
      <CardContent>
        <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
          {quote?.title}
        </Typography>
        <Typography variant="body1" sx={{ color: "#171D31", marginBottom: 4 }}>
          {quote?.executive_summary}
        </Typography>

        {/* Scope of Work */}

        <ScopeOfWorkSection scope={quote?.scope_of_work}/>
       
        <Typography variant="h5" mt={2} gutterBottom sx={{ fontWeight: "bold" }}>
          Approach
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: "#171D31" }}>
          {quote?.approach}
        </Typography>
        {/* Timeline */}
        <PremiumTimeline data={quote?.timeline}/>
        <PricingSection pricing={quote?.pricing}/>
        <AssumptionsAndCriteria assumptions={quote?.assumptions} acceptance_criteria={quote?.acceptance_criteria}/>

        {/* Assumptions */}
        <Box>
          {/* Terms and Conditions */}
          <Divider sx={{ marginY: 2 }} />
          <Typography
            variant="body2"
            sx={{ fontStyle: "italic", marginTop: 4, color: "red" }}
          >
            {quote?.terms_and_conditions}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default Clientquote;
