import { AccessTimeFilledOutlined } from "@mui/icons-material";
import { Box, Card, Chip, Step, StepLabel, Stepper, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

const PremiumTimeline = ({ data }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Define card dimensions
  const cardHeight = "200px";
  const cardWidth = "250px";

  // Function to chunk data into groups based on the number of steps per row
  const chunkData = (arr, size) => {
    const result = [];
    for (let i = 0; i < arr.length; i += size) {
      result.push(arr.slice(i, i + size));
    }
    return result;
  };

  // Determine the number of steps to show per row
  const stepsPerRow = isMobile ? 1 : 4; // Adjust number of steps per row based on screen size

  // Chunk the data into rows
  const groupedData = chunkData(data, stepsPerRow);

  const renderStepper = (steps) => (
    <Stepper
      alternativeLabel={!isMobile}
      orientation={isMobile ? "vertical" : "horizontal"}
      sx={{
        display: "flex",
        flexWrap: "wrap", // Ensure that steps wrap correctly to the next row
      }}
    >
      {steps?.map((step, index) => (
        <Step key={index} sx={{ width: "100%" }}>
          <StepLabel
            StepIconComponent={() => (
              <AccessTimeFilledOutlined
                sx={{
                  fontSize: "30px", // Consistent icon size
                }}
              />
            )}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Card
                sx={{
                  p: 2,
                  backgroundColor: "#1A1E3A",
                  color: "#fff",
                  borderRadius: 2,
                  boxShadow: 4,
                  height: cardHeight,
                  width: cardWidth,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  textAlign: "center",
                }}
              >
                <Typography variant="h6" sx={{ fontSize: "15px", fontWeight: "bold" }}>
                  {step?.outcome}
                </Typography>
                <Chip variant="outlined" sx={{ color: "#fff" }} label={step.duration} />
                <Box mt={1}>
                  {step?.key_activities?.map((task, idx) => (
                    <Typography key={idx} variant="body2" sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      - {task}
                    </Typography>
                  ))}
                </Box>
              </Card>
            </Box>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Timeline
      </Typography>

      {groupedData.map((group, index) => (
        <Box key={index} mb={4}>
          {renderStepper(group)}
        </Box>
      ))}
    </Box>
  );
};

export default PremiumTimeline;
