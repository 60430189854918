import React from "react";
import { Card, CardContent, Typography, Grid, Box } from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import SyncIcon from "@mui/icons-material/Sync";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const CriticalPathComponent = ({ critical_path }) => {
  return (
    <Box sx={{ marginBottom: 4 }}>
      <Card
        sx={{
          backgroundColor: "#1A1E3A",
          color: "#FFFFFF",
          padding: 3,
          marginBottom: 4,
        }}
      >
        <CardContent>
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold", marginBottom: 2, color: "#FFFFFF" }}
          >
            Critical Path
          </Typography>

          {critical_path?.summary?.map((item, index) => (
            <Box key={index} sx={{ marginBottom: 2 }}>
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", color: "#FFFFFF", marginBottom: 1 }}
              >
                {item?.item}
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box display="flex" alignItems="center">
                    <EventIcon sx={{ color: "#10B981", marginRight: 1 }} />
                    <Typography variant="body2" sx={{ color: "#A0AEC0" }}>
                      <strong>Deadline:</strong> {item?.deadline}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box display="flex" alignItems="center">
                    <SyncIcon sx={{ color: "#F59E0B", marginRight: 1 }} />
                    <Typography variant="body2" sx={{ color: "#A0AEC0" }}>
                      <strong>Dependencies:</strong>{" "}
                      {item?.dependencies.join(", ")}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box display="flex" alignItems="center">
                    <ErrorOutlineIcon
                      sx={{ color: "#EF4444", marginRight: 1 }}
                    />
                    <Typography variant="body2" sx={{ color: "#A0AEC0" }}>
                      <strong>Impact if Delayed:</strong>{" "}
                      {item?.impact_if_delayed}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ))}
        </CardContent>
      </Card>
    </Box>
  );
};

export default CriticalPathComponent;
