import { CameraAlt, Edit, Gavel, Person } from '@mui/icons-material';
import { Box, Card, Chip, Grid, Typography } from '@mui/material';
import React from 'react';

const scopeOfWork = [
  {
    title: 'Photo Shoot',
    description: 'Conduct a professional photo shoot for the olive oil brand, including 4 hours of shooting time at a pre-booked location.',
    deliverables: ['High-quality images from the shoot', 'One year of usage rights documentation'],
    icon: <CameraAlt />,
  },
  {
    title: 'Model Sourcing',
    description: 'Source and secure one model for the photo shoot, ensuring they align with the brand\'s image and target audience.',
    deliverables: ['Secured model for the shoot'],
    icon: <Person />,
  },
  {
    title: 'Editing',
    description: 'Perform 3 hours of post-production editing on the images captured during the shoot to ensure high-quality final deliverables.',
    deliverables: ['Edited images ready for use'],
    icon: <Edit />,
  },
  {
    title: 'Usage Rights',
    description: 'Provide one year of usage rights for the images captured during the shoot, with an option for the customer to renew these rights annually.',
    deliverables: ['Usage rights agreement'],
    icon: <Gavel />,
  },
];

const ScopeOfWorkSection = ({scope}) => {
  return (
    <Box sx={{ maxWidth: 1200, mx: 'auto', mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Scope of Work
      </Typography>

      <Grid container spacing={4}>
        {scope?.map((item, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <Card
              sx={{
                p: 3,
                backgroundColor: '#1A1E3A',
                color: '#fff',
                borderRadius: 2,
                boxShadow: 4,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              {/* Title with Icon */}
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                {item.icon}
                <Typography variant="h5" sx={{ fontWeight: 'bold', ml: 1 }}>
                  {item.outcome}
                </Typography>
              </Box>

              {/* Description */}
              <Typography variant="body1" sx={{ color: '#A0AEC0', mb: 2 }}>
                {item.description}
              </Typography>

              {/* Deliverables */}
              <Box>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
                  Deliverables:
                </Typography>
                <Box>
                  {item.deliverables.map((deliverable, idx) => (
                    <Chip
                      key={idx}
                      label={deliverable}
                      sx={{
                        backgroundColor: '#fff',
                        color: 'black',
                        fontWeight: 'bold',
                        mr: 1,
                        mb: 1,
                      }}
                    />
                  ))}
                </Box>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ScopeOfWorkSection;
