import React, { useState, useEffect } from "react";
import DataTable from "../../common/custom-table";
import CustomButton from "../../common/custom-button";
import { Box, Pagination, CircularProgress, Typography } from "@mui/material";

export default function DocumentTable({ documents, handleDelete, loading }) {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 3;

  const displayedDocuments = documents?.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const columns = [
    {
      field: "friendly_name",
      headerName: "Title",
      render: (row) => row?.chunks[0]?.friendly_name || "Untitled Quote",
    },
    {
      field: "overview",
      headerName: "Impact Summary",
      render: (row) =>
        row?.chunks[0]?.impact_summary || "No description available",
    },
    {
      field: "Action",
      headerName: "Action",
      render: (row) => {
        return (
          <CustomButton
            variant="contained"
            sx={{
              background: "red",
              color: "white",
              width: "100px",
            }}
            onClick={() => handleDelete(row?.id)}
          >
            Delete
          </CustomButton>
        );
      },
    },
  ];

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <Box>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="300px"
        >
          <CircularProgress />
          <Typography variant="body1" sx={{ marginLeft: 2 }}>
            Loading documents...
          </Typography>
        </Box>
      ) : (
        <>
          <DataTable rows={displayedDocuments} columns={columns} />
          <Pagination
            count={Math.ceil(documents?.length / rowsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            color="primary"
            style={{
              marginTop: "1rem",
              display: "flex",
              justifyContent: "center",
            }}
          />
        </>
      )}
    </Box>
  );
}
