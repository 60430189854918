import { Box, Tooltip, Typography } from "@mui/material";
import { FileText } from "lucide-react";
import React from "react";
import CustomButton from "../../common/custom-button";

export default function InvestmentSuggestion({ data, setRefinementFeedback }) {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <FileText className="w-5 h-5" />
        <Typography variant="h5" fontWeight={"bold"}>
          Improvement Suggestions
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 2,
        }}
      >
        {data?.map((suggestion, index) => {
          let parsedSuggestion = suggestion;
          if (typeof suggestion === "string") {
            try {
              parsedSuggestion = JSON.parse(suggestion.replace(/'/g, '"'));
            } catch (e) {
              console.error("Failed to parse suggestion JSON:", suggestion, e);
              return (
                <li key={index} className="mb-2 text-red-500">
                  Invalid suggestion format.
                </li>
              );
            }
          }
          return (
            <Tooltip title={parsedSuggestion?.description} key={index} arrow>
              <CustomButton
                sx={{
                  background: "#171D31",
                  color: "white",
                  mt: 2,
                  height: 50,
                  borderRadius: 10,
                }}
                onClick={() => {
                  setRefinementFeedback(parsedSuggestion?.description);
                }}
              >
                {parsedSuggestion?.title}
              </CustomButton>
            </Tooltip>
          );
        })}
      </Box>
    </Box>
  );
}
