import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const downloadPDF = (id, name) => {
  const input = document.getElementById(id);

  html2canvas(input, { scale: 2 }).then((canvas) => {
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");

    const imgWidth = 210; // A4 width in mm
    const pageHeight = 297; // A4 height in mm (standard size)
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;

    let position = 0;

    // First page
    pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    // If the content overflows, add new pages
    while (heightLeft > 0) {
      position = heightLeft - imgHeight; // Adjust position to prevent cutting
      pdf.addPage();
      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    pdf.save(`${name}.pdf`);
  });
};
