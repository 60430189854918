import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const KeyObjectivesComponent = ({ key_objectives }) => {
  return (
    <Box sx={{ marginBottom: 4 }}>
      <Card sx={{ backgroundColor: "#1A1E3A", color: "#FFFFFF", padding: 3 }}>
        <CardContent>
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold", marginBottom: 2, color: "#FFFFFF" }}
          >
            Key Objectives
          </Typography>
          <Typography
            variant="body1"
            sx={{ marginBottom: 2, color: "#A0AEC0" }}
          >
            {key_objectives?.narrative}
          </Typography>

          <List>
            {key_objectives?.summary?.map((objective, index) => (
              <ListItem key={index}>
                <CheckCircleIcon sx={{ color: "#10B981", marginRight: 1 }} />
                <ListItemText
                  primary={objective?.objective}
                  secondary={objective?.success_criteria}
                  sx={{
                    "& .MuiListItemText-primary": {
                      color: "#FFFFFF",
                      fontWeight: "bold",
                    },
                    "& .MuiListItemText-secondary": { color: "#A0AEC0" },
                  }}
                />
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
    </Box>
  );
};

export default KeyObjectivesComponent;
