import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Divider,
  Box,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const ResourceAnalysisComponent = ({ analysis }) => {
  return (
    <Box>
      {/* Resource Analysis */}
      <Card
        variant="outlined"
        sx={{ marginBottom: 2, backgroundColor: "#1A1E3A", color: "#FFFFFF" }}
      >
        <CardContent>
          <Typography variant="h5" sx={{ fontWeight: "bold" }} gutterBottom>
            Resource Analysis
          </Typography>
          <Typography>{analysis.resource_analysis.narrative}</Typography>

          {/* Utilization Section */}
          <Divider sx={{ marginY: 2, backgroundColor: "#FFFFFF" }} />
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", color: "#FFFFFF" }}
          >
            Utilization
          </Typography>

          <Grid container spacing={3} sx={{ mt: 2 }}>
            {analysis.resource_analysis.summary.utilization.by_role.map(
              (role) => (
                <Grid item xs={12} sm={6} key={role.role}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", color: "#FFFFFF" }}
                  >
                    {role.role}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#A0AEC0" }}>
                    Utilization: {role.utilization}
                  </Typography>

                  {/* Progress Bar with conditional color */}
                  <LinearProgress
                    variant="determinate"
                    value={parseFloat(role?.utilization)}
                    sx={{
                      height: 10,
                      borderRadius: 5,
                      backgroundColor: "#2D3748", // Background for unfilled portion
                      "& .MuiLinearProgress-bar": {
                        backgroundColor:
                          role.utilization > 80 ? "#10B981" : "#F59E0B", // Green for >80%, yellow otherwise
                      },
                    }}
                  />
                </Grid>
              )
            )}
          </Grid>

          {/* Efficiency Opportunities Section */}
          <Divider sx={{ marginY: 2, backgroundColor: "#FFFFFF" }} />
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", color: "#FFFFFF" }}
          >
            Efficiency Opportunities
          </Typography>

          <List sx={{ mt: 2 }}>
            {analysis.resource_analysis.summary.efficiency_opportunities.map(
              (opportunity, index) => (
                <ListItem key={index}>
                  <CheckCircleIcon sx={{ color: "#10B981", marginRight: 1 }} />
                  <ListItemText
                    primary={opportunity}
                    sx={{ color: "#A0AEC0" }}
                  />
                </ListItem>
              )
            )}
          </List>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ResourceAnalysisComponent;
