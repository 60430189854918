import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';
const LANGSERVE_BASE_URL = process.env.REACT_APP_LANGSERVE_URL || '';
const RAG_BACKEND_URL = process.env.REACT_APP_RAG_BACKEND_URL || '';

console.log('API_BASE_URL:', API_BASE_URL);
console.log('LANGSERVE_BASE_URL:', LANGSERVE_BASE_URL);

export const fetchQuotes = async () => {
  const url = `${API_BASE_URL}/api/quotes`;
  console.log('Fetching all quotes from URL:', url);
  try {
    const response = await axios.get(url);
    console.log('Fetched quotes response:', response);
    return response.data;
  } catch (error) {
    console.error('Error fetching quotes:', error.response || error);
    throw error;
  }
};

export const fetchQuoteDetails = async (quoteId, version = 'latest') => {
  const url = `${API_BASE_URL}/api/quotes/${quoteId}/${version}`;
  console.log(`Fetching quote details from URL: ${url}`);
  try {
    const response = await axios.get(url);
    console.log('Raw response data:', response.data);
    
    let parsedQuote = response.data;
    
    const jsonFields = ['quote_request', 'client_quote', 'work_breakdown_structure', 'margin_analysis', 'risk_assessment', 'internal_brief'];
    
    jsonFields.forEach(field => {
      if (typeof parsedQuote[field] === 'string') {
        try {
          parsedQuote[field] = JSON.parse(parsedQuote[field]);
        } catch (parseError) {
          console.error(`Error parsing ${field}:`, parseError);
          console.log(`${field} content:`, parsedQuote[field]);
          parsedQuote[field] = {};
        }
      }
    });

    if (!Array.isArray(parsedQuote.improvement_suggestions)) {
      parsedQuote.improvement_suggestions = [];
    }

    return parsedQuote;
  } catch (error) {
    console.error('Error in fetchQuoteDetails:', error.response || error);
    throw error;
  }
};

export const fetchSpanishQuoteStatus = async (quoteId, version = 'latest') => {
  const url = `${API_BASE_URL}/api/quotes/${quoteId}/${version}/spanish`;
  console.log(`Fetching Spanish quote status from URL: ${url}`);
  try {
    const response = await axios.get(url);
    return {
      translation_status: response.data.translation_status,
      // You might want to include other relevant fields here
    };
  } catch (error) {
    console.error('Error fetching Spanish quote status:', error.response || error);
    throw error;
  }
};

export const fetchSpanishQuoteDetails = async (quoteId, version = 'latest') => {
  const url = `${API_BASE_URL}/api/quotes/${quoteId}/${version}/spanish`;
  console.log(`Fetching Spanish quote details from URL: ${url}`);
  try {
    const response = await axios.get(url);
    console.log('Raw Spanish response data:', response.data);
    
    let parsedQuote = response.data;
    
    const jsonFields = ['quote_request', 'client_quote', 'work_breakdown_structure', 'margin_analysis', 'risk_assessment', 'internal_brief'];
    
    jsonFields.forEach(field => {
      if (typeof parsedQuote[field] === 'string') {
        try {
          parsedQuote[field] = JSON.parse(parsedQuote[field]);
        } catch (parseError) {
          console.error(`Error parsing ${field}:`, parseError);
          console.log(`${field} content:`, parsedQuote[field]);
          parsedQuote[field] = {};
        }
      }
    });

    if (!Array.isArray(parsedQuote.improvement_suggestions)) {
      parsedQuote.improvement_suggestions = [];
    }

    return parsedQuote;
  } catch (error) {
    console.error('Error in fetchSpanishQuoteDetails:', error.response || error);
    throw error;
  }
};

export const streamQuoteGeneration = async (quoteRequest, callbacks) => {
  const url = `${LANGSERVE_BASE_URL}/generate_quote/stream`;
  console.log('Streaming quote generation from URL:', url);

  const { onStart, onChunk, onSuccess, onError } = callbacks;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ input: { quote_request: quoteRequest } }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    onStart?.();

    const reader = response.body.getReader();
    const decoder = new TextDecoder();

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      
      const chunk = decoder.decode(value);
      const lines = chunk.split('\n').filter(line => line.trim() !== '');
      
      for (const line of lines) {
        if (line.startsWith('data: ')) {
          const data = JSON.parse(line.slice(5));
          onChunk?.(data);
        }
      }
    }

    onSuccess?.();
  } catch (err) {
    console.error('Error in streamQuoteGeneration:', err);
    onError?.(err);
  }
};

export const streamQuoteRefinement = async (quoteId, feedback, callbacks) => {
  const url = `${LANGSERVE_BASE_URL}/refine_quote/stream`;
  console.log('Streaming quote refinement from URL:', url);

  const { onStart, onChunk, onSuccess, onError } = callbacks;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ input: { quote_id: quoteId, feedback } }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    onStart?.();

    const reader = response.body.getReader();
    const decoder = new TextDecoder();

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      
      const chunk = decoder.decode(value);
      const lines = chunk.split('\n').filter(line => line.trim() !== '');
      
      for (const line of lines) {
        if (line.startsWith('data: ')) {
          const data = JSON.parse(line.slice(5));
          onChunk?.(data);
        }
      }
    }

    onSuccess?.();
  } catch (err) {
    console.error('Error in streamQuoteRefinement:', err);
    onError?.(err);
  }
};

export const fetchDocuments = async () => {
  try {
    const response = await axios.get(`${RAG_BACKEND_URL}/list_documents`);
    return response.data.documents;
  } catch (error) {
    console.error('Error fetching documents:', error);
    throw error;
  }
};

export const processDocument = async (text) => {
  try {
    const response = await axios.post(`${RAG_BACKEND_URL}/process_document`, { text });
    return response.data;
  } catch (error) {
    console.error('Error processing document:', error);
    throw error;
  }
};

export const uploadFile = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  try {
    const response = await axios.post(`${RAG_BACKEND_URL}/upload_file`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
};

export const deleteDocument = async (documentId) => {
  try {
    const response = await axios.delete(`${RAG_BACKEND_URL}/delete_document/${documentId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting document:', error);
    throw error;
  }
};

// QUOTE DRAFT ROUTES ADDED 9/23

export const deleteDraftQuote = async (draftId) => {
  const url = `${LANGSERVE_BASE_URL}/api/quotes/draft/${draftId}`;
  try {
    const response = await axios.delete(url);
    return response.data;
  } catch (error) {
    console.error('Error deleting draft quote:', error);
    throw error;
  }
};

export const createDraftQuote = async (data) => {
  const url = `${LANGSERVE_BASE_URL}/api/quotes/draft`;
  try {
    const response = await axios.post(url, data);
    return response.data;
  } catch (error) {
    console.error('Error creating draft quote:', error);
    throw error;
  }
};

export const updateDraftQuote = async (draftId, data) => {
  const url = `${LANGSERVE_BASE_URL}/api/quotes/draft/${draftId}`;
  try {
    const response = await axios.put(url, data);
    return response.data;
  } catch (error) {
    console.error('Error updating draft quote:', error);
    throw error;
  }
};

export const listDraftQuotes = async () => {
  const url = `${LANGSERVE_BASE_URL}/api/quotes/drafts`;
  try {
    console.log('Fetching draft quotes from:', url);
    const response = await axios.get(url);
    console.log('Draft quotes response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error listing draft quotes:', error.response || error);
    if (error.response) {
      console.error('Error response data:', error.response.data);
      console.error('Error response status:', error.response.status);
      console.error('Error response headers:', error.response.headers);
    }
    throw error;
  }
};

export const generateQuoteFromDraft = async (draftId, callbacks) => {
  const url = `${LANGSERVE_BASE_URL}/generate_quote/stream`;
  console.log('Generating quote from draft, URL:', url);

  const { onStart, onChunk, onSuccess, onError } = callbacks;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ input: { draft_id: draftId } }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    onStart?.();

    const reader = response.body.getReader();
    const decoder = new TextDecoder();

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      
      const chunk = decoder.decode(value);
      const lines = chunk.split('\n').filter(line => line.trim() !== '');
      
      for (const line of lines) {
        if (line.startsWith('data: ')) {
          const data = JSON.parse(line.slice(5));
          onChunk?.(data);
        }
      }
    }

    onSuccess?.();
  } catch (err) {
    console.error('Error in generateQuoteFromDraft:', err);
    onError?.(err);
  }
};