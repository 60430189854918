import { CircularProgress } from "@mui/material";
import React from "react";

export default function CustomCircular() {
  return (
    <CircularProgress
      sx={{
        color: "#171D31",
      }}
    />
  );
}
