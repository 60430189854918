import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  title: "",
  executiveSummary: "",
  scopeOfWork: [],
  approach: "",
  timeline: [],
  pricing: {
    laborCosts: [],
    materialCosts: [],
    totalLaborCost: 0,
    totalMaterialCost: 0,
    totalPrice: 0,
  },
  assumptions: [],
  acceptanceCriteria: [],
  termsAndConditions: "",
};

// Create the slice
const clientQuoteSlice = createSlice({
  name: "clientQuote",
  initialState,
  reducers: {
    setClientQuote: (state, action) => {
      return { ...action.payload };
    },
    updateClientQuote: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setClientQuote, updateClientQuote } = clientQuoteSlice.actions;
export default clientQuoteSlice.reducer;
