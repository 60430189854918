import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

const DataTable = ({ rows, columns }) => {
  return (
    <TableContainer component={Paper} style={{ marginTop: "2rem" }}>
      <Table>
        <TableHead
          sx={{
            background: "#171D31",
            color: "white",
            whiteSpace: "pre",
          }}
        >
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.field}>
                <Typography variant="subtitle2" fontWeight="bold" color="#fff">
                  {column.headerName}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rowIndex) => (
            <TableRow key={rowIndex} hover>
              {columns.map((column) => (
                <TableCell key={column.field}>
                  <Typography>
                    {column.render
                      ? column.render(row)
                      : row[column.field] || "N/A"}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DataTable;
