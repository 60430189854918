import React from "react";
import { TextField } from "@mui/material";

const CustomTextField = ({
  value,
  onChange,
  placeholder,
  rows = 4,
  multiline = true,
  variant = "outlined",
  sx = {},
  ...props
}) => {
  return (
    <TextField
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      rows={rows}
      multiline={multiline}
      variant={variant}
      sx={{
        flexGrow: 1,
        input: {
          padding: "0.75rem",
          fontSize: "1rem",
          color: "#fff", // White text color
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "#777",
          },
          "&:hover fieldset": {
            borderColor: "#777",
          },
          "&.Mui-focused fieldset": {
            borderColor: "#777",
          },
        },
        "& .MuiInputBase-root": {
          color: "#fff",
        },
        ...sx, // Merge additional styles
      }}
      {...props} // Spread any additional props to the TextField
    />
  );
};

export default CustomTextField;
