import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  FormControlLabel,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import {
  AlertTriangle,
  ChevronDown,
  ChevronUp,
  Clock,
  FileText,
} from "lucide-react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  fetchQuoteDetails,
  fetchSpanishQuoteDetails,
  streamQuoteRefinement,
} from "../api";
import { setClientQuote } from "../store/slice/clientQuoteSlice";
import { setProjectOverview } from "../store/slice/internal-analysis";
import { setInternalBrief } from "../store/slice/internal-brief";
import CustomButton from "./common/custom-button";
import CustomCircular from "./common/custom-circular";
import InvestmentSuggestion from "./quote/improvement-suggestion";
import QuoteHeader from "./quote/quote-header";

export default function QuoteDetails() {
  const { quoteId, version } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [quoteDetails, setQuoteDetails] = useState(null);
  const [spanishQuoteDetails, setSpanishQuoteDetails] = useState(null);
  const [refinementFeedback, setRefinementFeedback] = useState("");
  const [isRefining, setIsRefining] = useState(false);
  const [streamingOutput, setStreamingOutput] = useState("");
  const [error, setError] = useState(null);
  const streamingOutputRef = useRef(null);
  const [expandedSections, setExpandedSections] = useState({});
  const [language, setLanguage] = useState("en");
  const [isTranslationReady, setIsTranslationReady] = useState(false);
  const [isCheckingTranslation, setIsCheckingTranslation] = useState(false);

  useEffect(() => {
    const loadQuoteDetails = async () => {
      try {
        const data = await fetchQuoteDetails(quoteId, version);
        setQuoteDetails(data);

        const spanishData = await fetchSpanishQuoteDetails(quoteId, version);
        setSpanishQuoteDetails(spanishData);
        setIsTranslationReady(spanishData.translation_status === "COMPLETED");
      } catch (err) {
        setError(`Failed to load quote details: ${err.message}`);
      }
    };
    loadQuoteDetails();
  }, [quoteId, version]);

  useEffect(() => {
    if (streamingOutputRef.current) {
      streamingOutputRef.current.scrollTop =
        streamingOutputRef.current.scrollHeight;
    }
  }, [streamingOutput]);

  const handleRefinement = async (e) => {
    e.preventDefault();
    setIsRefining(true);
    setStreamingOutput("Initiating quote refinement...\n");
    setError(null);

    try {
      await streamQuoteRefinement(quoteId, refinementFeedback, {
        onStart: () => {
          setStreamingOutput(
            (prev) =>
              prev + "Connected to server. Starting quote refinement...\n"
          );
        },
        onChunk: (chunk) => {
          let outputText = "";
          switch (chunk.event) {
            case "status":
              outputText = `${chunk.data}\n`;
              break;
            case "progress":
              outputText = `Step ${chunk.data.step}/${chunk.data.total}: ${chunk.data.message}\n`;
              break;
            case "error":
              outputText = `Error: ${chunk.data}\n`;
              setError(chunk.data);
              break;
            case "complete":
              outputText = "Quote refinement completed.\n";
              navigate(`/quote/${chunk.data.quote_id}/latest`, {
                replace: true,
              });
              break;
            default:
              outputText = `Received: ${JSON.stringify(chunk)}\n`;
          }
          setStreamingOutput((prev) => prev + outputText);
        },
        onSuccess: () => {
          setStreamingOutput(
            (prev) => prev + "Quote refinement process finished.\n"
          );
          setIsRefining(false);
        },
        onError: (err) => {
          setError(
            `Error in quote refinement: ${err.message || "Unknown error"}`
          );
          setIsRefining(false);
        },
      });
    } catch (error) {
      setError(`Failed to refine quote: ${error.message || "Unknown error"}`);
      setIsRefining(false);
    }
  };

  const toggleSection = (section) => {
    setExpandedSections((prev) => ({ ...prev, [section]: !prev[section] }));
  };

  const renderSection = (title, content, icon, url) => {
    const isExpanded = expandedSections[title] === false;
    return (
      <div className="mb-8 border rounded-lg shadow-sm">
        <Box className="w-full flex items-center justify-between p-4 bg-gray-50 rounded-t-lg focus:outline-none">
          <div className="flex items-center">
            {icon}
            <h2 className="text-xl font-semibold ml-2">{title}</h2>
          </div>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {content ? (
              <IconButton onClick={() => toggleSection(title)}>
                {isExpanded ? (
                  <ChevronUp className="w-5 h-5" />
                ) : (
                  <ChevronDown className="w-5 h-5" />
                )}
              </IconButton>
            ) : (
              <CustomButton
                onClick={() => {
                  if (url === "client-quote") {
                    dispatch(setClientQuote(quoteDetails.client_quote)); // Updated
                    navigate(`/quote/${url}`);
                  } else if (url === "internal-analysis") {
                    dispatch(
                      setProjectOverview(quoteDetails.internal_analysis)
                    ); // Updated
                    navigate(`/quote/${url}`);
                  } else {
                    dispatch(setInternalBrief(quoteDetails.internal_brief)); // Updated
                    navigate(`/quote/${url}`);
                  }
                }}
              >
                View
              </CustomButton>
            )}
          </Box>
        </Box>
        {isExpanded && <div className="p-4">{content}</div>}
      </div>
    );
  };

  if (error) {
    return (
      <div className="max-w-4xl mx-auto p-6">
        <h1 className="text-2xl font-bold text-red-600 mb-4">Error</h1>
        <p className="text-lg mb-4">{error}</p>
        <Link
          to="/"
          className="inline-block bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors"
        >
          ← Back to Quotes
        </Link>
      </div>
    );
  }

  if (!quoteDetails) {
    return (
      <p className="text-center text-xl mt-8">
        <CustomCircular />
      </p>
    );
  }

  const currentQuote =
    language === "en" ? quoteDetails : spanishQuoteDetails || quoteDetails;

  const {
    quote_id,
    version: quoteVersion,
    title,
    created_at,
    improvement_suggestions,
  } = currentQuote;

  return (
    <div className="max-w-5xl mx-auto p-6">
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={2}
      >
        <Link to="/" className="inline-block text-blue-500 hover:underline">
          ← Back to Quotes
        </Link>
        <FormControlLabel
          control={
            <Switch
              onClick={() =>
                setLanguage((lang) => (lang === "en" ? "es" : "en"))
              }
              disabled={!isTranslationReady || isCheckingTranslation}
            />
          }
          label={
            isCheckingTranslation
              ? "Checking..."
              : isTranslationReady
              ? language === "en"
                ? "Ver en Español"
                : "View in English"
              : "Translation in Progress"
          }
        />
      </Box>

      <QuoteHeader
        title={title}
        quoteVersion={quoteVersion}
        created_at={created_at}
        language={language}
      />
      {renderSection(
        "Client Quote",
        null,
        <FileText className="w-5 h-5" />,
        "client-quote"
      )}
      {renderSection(
        "Internal Analysis",
        null,
        <AlertTriangle className="w-5 h-5" />,
        "internal-analysis"
      )}
      {renderSection(
        "Internal Brief",
        null,
        <AlertTriangle className="w-5 h-5" />,
        "internal-brief"
      )}

      {improvement_suggestions && improvement_suggestions.length > 0 && (
        <InvestmentSuggestion
          data={improvement_suggestions}
          setRefinementFeedback={setRefinementFeedback}
        />
      )}

      <div className="mt-8 border rounded-lg p-6">
        <h2 className="text-xl font-semibold mb-4">Quote Refinement</h2>
        <form onSubmit={handleRefinement}>
          <textarea
            value={refinementFeedback}
            onChange={(e) => setRefinementFeedback(e.target.value)}
            placeholder="Enter refinements or updates to the quote..."
            className="w-full min-h-[100px] p-2 border rounded mb-4"
          />
          <button
            type="submit"
            disabled={isRefining}
            className={`px-4 py-2 bg-blue-500 text-white rounded ${
              isRefining ? "opacity-50 cursor-not-allowed" : "hover:bg-blue-600"
            }`}
          >
            {isRefining ? "Refining Quote..." : "Refine Quote"}
          </button>
        </form>
      </div>

      {(isRefining || streamingOutput) && (
        <div
          ref={streamingOutputRef}
          className="mt-4 p-4 bg-gray-100 rounded-lg max-h-[300px] overflow-y-auto whitespace-pre-wrap font-mono text-sm"
        >
          {streamingOutput}
        </div>
      )}

      {error && (
        <p role="alert" className="mt-4 text-red-500">
          {error}
        </p>
      )}
    </div>
  );
}
