import { ArrowBack } from "@mui/icons-material";
import { Box, Button, IconButton } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AnalysisComponent from "../../../components/quote/internal-analysis";

export default function Analysis() {
  const internalAnalysis = useSelector((state) => state?.internalAnalysis);
  const navigate = useNavigate();
  return (
    <Box className="max-w-7xl mx-auto p-6">
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        px={2}
      >
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBack />
        </IconButton>
        <Button
          onClick={() => downloadPDF("internal-analysis", "client")}
          sx={{
            background: "#171D31",
            color: "#fff",
          }}
        >
          Download
        </Button>
      </Box>
      <AnalysisComponent analysis={internalAnalysis} id={"internal-analysis"} />
    </Box>
  );
}
