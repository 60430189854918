import React from "react";
import {
  Container,
  Typography,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
  Grid,
} from "@mui/material";
import ExecutionPlanComponent from "./execution-plan";
import RiskMitigationComponent from "./risk-mitigation";
import KeyAssumptionsAndSuccessFactors from "./key-assumption";
import CriticalPathComponent from "./critical-path";
import KeyObjectivesComponent from "./key-objective";

// Reusable Section Component for uniform design
const Section = ({ title, children }) => (
  <Box sx={{ marginBottom: 4 }}>
    <Card
      sx={{ marginBottom: 4, backgroundColor: "#1A1E3A", color: "#FFFFFF" }}
    >
      <CardContent>
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold", color: "#FFFFFF", marginBottom: 2 }}
        >
          {title}
        </Typography>
        {children}
      </CardContent>
    </Card>
  </Box>
);

const InternalBriefComponent = ({ briefData, id }) => {
  return (
    <Container id={id} sx={{ marginTop: 4 }}>
      {/* Mission Statement */}
      <Section title="Mission Statement">
        <Typography variant="body1" color="#FFFFFF">
          {briefData.mission_statement}
        </Typography>
      </Section>

      {/* Project Overview */}
      <Section title="Project Overview">
        <Typography variant="body1" color="#FFFFFF" paragraph>
          {briefData.project_overview.narrative}
        </Typography>
        <Typography variant="body2" color="#A0AEC0">
          {briefData.project_overview.summary}
        </Typography>
      </Section>

      {/* Key Objectives */}
      <KeyObjectivesComponent key_objectives={briefData?.key_objectives} />
      <Section title="Key Objectives">
        <Typography variant="body1" color="#FFFFFF">
          {briefData.key_objectives.narrative}
        </Typography>
        <List>
          {briefData.key_objectives.summary.map((objective, index) => (
            <ListItem key={index}>
              <ListItemText
                primary={objective.objective}
                secondary={objective.success_criteria}
                sx={{
                  "& .MuiListItemText-primary": { color: "#FFFFFF" },
                  "& .MuiListItemText-secondary": { color: "#A0AEC0" },
                }}
              />
            </ListItem>
          ))}
        </List>
      </Section>

      {/* Execution Plan */}
      <ExecutionPlanComponent executionPlan={briefData?.execution_plan} />

      {/* Critical Path */}
      <CriticalPathComponent critical_path={briefData?.critical_path} />

      {/* Risk Mitigation Focus */}
      <RiskMitigationComponent riskData={briefData?.risk_mitigation_focus} />

      <KeyAssumptionsAndSuccessFactors briefData={briefData} />
    </Container>
  );
};

export default InternalBriefComponent;
