import React, { useState, useEffect } from 'react';
import {
  Typography,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Paper,
  CircularProgress,
  Box,
  IconButton,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { 
  createDraftQuote,
  updateDraftQuote,
  listDraftQuotes,
  generateQuoteFromDraft,
  deleteDraftQuote
} from '../api';

function QuoteDrafts() {
  const [draftTitle, setDraftTitle] = useState('');
  const [draftDescription, setDraftDescription] = useState('');
  const [draftContent, setDraftContent] = useState('');
  const [drafts, setDrafts] = useState([]);
  const [selectedDraft, setSelectedDraft] = useState(null);
  const [file, setFile] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [streamingOutput, setStreamingOutput] = useState('');
  const [error, setError] = useState(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [draftToDelete, setDraftToDelete] = useState(null);

  useEffect(() => {
    fetchDrafts();
  }, []);

  const fetchDrafts = async () => {
    try {
      const response = await listDraftQuotes();
      setDrafts(response);
    } catch (error) {
      setError('Error fetching drafts: ' + error.message);
    }
  };

  const handleCreateDraft = async () => {
    setLoading(true);
    try {
      const response = await createDraftQuote({ title: draftTitle, content: draftDescription });
      setDraftContent(response.content);
      setSelectedDraft(response);
      setSuggestions(response.improvement_suggestions || []);
      fetchDrafts();
      setError(null);
    } catch (error) {
      setError('Error creating draft: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateDraft = async () => {
    if (!selectedDraft) return;
    setLoading(true);
    try {
      const response = await updateDraftQuote(selectedDraft.draft_id, { content: draftContent });
      setSuggestions(response.improvement_suggestions || []);
      setError(null);
    } catch (error) {
      setError('Error updating draft: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteDraft = async () => {
    if (!draftToDelete) return;
    setLoading(true);
    try {
      await deleteDraftQuote(draftToDelete.draft_id);
      fetchDrafts();
      if (selectedDraft && selectedDraft.draft_id === draftToDelete.draft_id) {
        setSelectedDraft(null);
        setDraftContent('');
        setSuggestions([]);
      }
      setError(null);
    } catch (error) {
      setError('Error deleting draft: ' + error.message);
    } finally {
      setLoading(false);
      setDeleteConfirmOpen(false);
      setDraftToDelete(null);
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    setFile(file);
    if (selectedDraft && file) {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append('file', file);
        await updateDraftQuote(selectedDraft.draft_id, formData);
        fetchDrafts();
        setError(null);
      } catch (error) {
        setError('Error uploading file: ' + error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSubmitQuote = async () => {
    if (!selectedDraft) return;
    setLoading(true);
    setStreamingOutput('');
    try {
      await generateQuoteFromDraft(selectedDraft.draft_id, {
        onStart: () => setStreamingOutput('Starting quote generation...'),
        onChunk: (chunk) => {
          setStreamingOutput((prev) => prev + JSON.stringify(chunk) + '\n');
        },
        onSuccess: () => {
          setStreamingOutput((prev) => prev + 'Quote generation completed.');
          setLoading(false);
        },
        onError: (error) => {
          setStreamingOutput((prev) => prev + 'Error: ' + error.message);
          setError('Error generating quote: ' + error.message);
          setLoading(false);
        },
      });
    } catch (error) {
      setError('Error submitting quote: ' + error.message);
      setLoading(false);
    }
  };

  return (
    <Box sx={{ display: 'flex', padding: '20px', flexDirection: 'column' }}>
      <Typography variant="h4" gutterBottom>
        Quote Drafts
      </Typography>
      {error && (
        <Alert severity="error" onClose={() => setError(null)} sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      <Box sx={{ display: 'flex', gap: '20px' }}>
        <Box sx={{ flex: 1 }}>
          <TextField
            label="Draft Title"
            value={draftTitle}
            onChange={(e) => setDraftTitle(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Describe what you're looking to quote"
            value={draftDescription}
            onChange={(e) => setDraftDescription(e.target.value)}
            fullWidth
            multiline
            rows={4}
            margin="normal"
          />
          <Button variant="contained" onClick={handleCreateDraft} disabled={loading}>
            Create Draft
          </Button>
          <List>
            {drafts.map((draft) => (
              <ListItem
                key={draft.draft_id}
                secondaryAction={
                  <IconButton 
                    edge="end" 
                    aria-label="delete" 
                    onClick={() => {
                      setDraftToDelete(draft);
                      setDeleteConfirmOpen(true);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemText
                  primary={draft.title}
                  secondary={`Created: ${new Date(draft.created_at).toLocaleString()}`}
                  onClick={() => {
                    setSelectedDraft(draft);
                    setDraftContent(draft.content);
                    setDraftTitle(draft.title);
                    setSuggestions(draft.improvement_suggestions || []);
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </ListItem>
            ))}
          </List>
        </Box>
        <Box sx={{ flex: 2 }}>
          {selectedDraft && (
            <>
              <Typography variant="h5" gutterBottom>
                {selectedDraft.title}
              </Typography>
              <TextField
                label="Draft Content"
                multiline
                rows={10}
                value={draftContent}
                onChange={(e) => setDraftContent(e.target.value)}
                fullWidth
                margin="normal"
              />
              <input
                type="file"
                accept=".pdf,.csv,.json"
                onChange={handleFileUpload}
                style={{ margin: '10px 0' }}
              />
              <Box sx={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                <Button variant="contained" onClick={handleUpdateDraft} disabled={loading}>
                  Save Draft
                </Button>
                <Button variant="contained" color="primary" onClick={handleSubmitQuote} disabled={loading}>
                  Submit for Generation
                </Button>
              </Box>
              <Typography variant="h6" style={{ marginTop: '20px' }}>
                Improvement Suggestions
              </Typography>
              <List>
                {suggestions.map((suggestion, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={suggestion.title} secondary={suggestion.description} />
                  </ListItem>
                ))}
              </List>
            </>
          )}
          {loading && <CircularProgress />}
          {streamingOutput && (
            <Paper style={{ marginTop: '20px', padding: '10px', maxHeight: '200px', overflow: 'auto' }}>
              <pre>{streamingOutput}</pre>
            </Paper>
          )}
        </Box>
      </Box>
      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this draft quote? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteDraft} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default QuoteDrafts;