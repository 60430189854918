import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { streamQuoteGeneration } from "../../api";
import MyStepper from "../../components/common/stepper";
import QuoteTable from "../../components/home/quote-table";
import CustomTextField from "../../components/common/custom-input";
import CustomButton from "../../components/common/custom-loading-button";

function HomePage() {
  const [quoteRequest, setQuoteRequest] = useState("");
  const [loading, setLoading] = useState(false);
  const [streamingOutput, setStreamingOutput] = useState("");
  const [error, setError] = useState(null);
  const [totalSteps, setTotalSteps] = useState(9);
  const [currentStep, setCurrentStep] = useState(1);
  const [stepMessages, setStepMessages] = useState([]);

  const streamingOutputRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (streamingOutputRef.current) {
      streamingOutputRef.current.scrollTop =
        streamingOutputRef.current.scrollHeight;
    }
  }, [streamingOutput]);

  const handleStreamQuoteGeneration = async (e) => {
    e.preventDefault();
    setLoading(true);
    setStreamingOutput("Initiating quote generation...\n");
    setError(null);
    setCurrentStep(1);
    setStepMessages(Array(totalSteps).fill(""));

    try {
      await streamQuoteGeneration(quoteRequest, {
        onStart: () => {
          setStreamingOutput(
            (prev) =>
              prev + "Connected to server. Starting quote generation...\n"
          );
        },
        onChunk: (chunk) => {
          let outputText = "";
          let updatedMessages = [...stepMessages];

          switch (chunk.event) {
            case "start":
              outputText = `Starting: ${chunk.data}\n`;
              updatedMessages[currentStep - 1] = outputText;
              setStepMessages(updatedMessages);
              break;

            case "status":
              outputText = `${chunk.data}\n`;
              updatedMessages[currentStep - 1] = outputText;
              setStepMessages(updatedMessages);
              break;

            case "progress":
              const { step, total, message } = chunk.data;
              setCurrentStep(step + 1);
              setTotalSteps(total);
              updatedMessages[step - 1] = message;
              setStepMessages(updatedMessages);
              outputText = `${message}\n`;
              break;

            case "end":
              outputText = `Completed: ${Object.keys(chunk.data)[0]}\n`;
              updatedMessages[currentStep - 1] = outputText;
              setStepMessages(updatedMessages);
              break;

            case "error":
              outputText = `Error: ${chunk.data}\n`;
              updatedMessages[currentStep - 1] = outputText;
              setStepMessages(updatedMessages);
              setError(chunk.data);
              setLoading(false);
              break;

            case "complete":
              outputText = "Quote generation completed.\n";
              updatedMessages[currentStep - 1] = outputText;
              setStepMessages(updatedMessages);
              setLoading(false);
              navigate(`/quote/${chunk.data.quote_id}/latest`);
              break;

            default:
              outputText = `Received: ${JSON.stringify(chunk)}\n`;
          }
          setStreamingOutput((prev) => prev + outputText);
        },
        onSuccess: () => {
          setStreamingOutput(
            (prev) => prev + "Quote generation process finished.\n"
          );
          setLoading(false);
        },
        onError: (err) => {
          setError(
            `Error in quote generation: ${err.message || "Unknown error"}`
          );
          setLoading(false);
        },
      });
    } catch (error) {
      setError(`Failed to generate quote: ${error.message || "Unknown error"}`);
      setLoading(false);
    }
  };

  return (
    <Box sx={{ maxWidth: "800px", margin: "0 auto", padding: "20px" }}>
      <Typography
        variant="h1"
        sx={{
          fontSize: "2.5rem",
          fontWeight: "bold",
          mb: "2rem",
          textAlign: "center",
          color: "#000",
        }}
      >
        Quote Generator
      </Typography>

      <Box
        sx={{
          background: "#171D31",
          padding: "1.5rem",
          borderRadius: "0.5rem",
          mb: "3rem",
        }}
      >
        <form onSubmit={handleStreamQuoteGeneration}>
          <Typography
            variant="h2"
            sx={{
              fontSize: "1.5rem",
              fontWeight: "semibold",
              mb: "1rem",
              color: "white",
            }}
          >
            What quote can I create for you today?
          </Typography>
          <Box sx={{ display: "flex", gap: "1rem", flexDirection: "column" }}>
            <CustomTextField
              rows={4}
              multiline
              placeholder="Enter your quote idea..."
              value={quoteRequest}
              onChange={(e) => setQuoteRequest(e.target.value)}
            />
            <CustomButton
              variant="contained"
              color="secondary"
              type="submit"
              disabled={loading}
              sx={{
                backgroundColor: "white",
                color: "#000",
                padding: "0.75rem 1rem",
                borderRadius: "0.25rem",
                fontWeight: "bold",
                width: "200px",
                alignContent: "center",
              }}
            >
              {loading ? "Generating..." : "Generate Quote"}
            </CustomButton>
          </Box>
        </form>
      </Box>

      {/* Custom Stepper */}
      {(loading || streamingOutput) && (
        <MyStepper
          currentStep={currentStep}
          totalSteps={totalSteps}
          stepMessages={stepMessages}
        />
      )}

      {error && (
        <Typography color="error" sx={{ mb: "1rem", whiteSpace: "pre-wrap" }}>
          {error}
        </Typography>
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: "1rem",
        }}
      >
        <Typography
          variant="h2"
          sx={{ fontSize: "1.5rem", fontWeight: "semibold", color: "#000" }}
        >
          Your Quotes
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/data")}
          sx={{
            padding: "0.5rem 1rem",
            borderRadius: "0.25rem",
            fontWeight: "bold",
            background: "#171D31",
          }}
        >
          Bring Your Own Data
        </Button>
      </Box>
      <QuoteTable />
    </Box>
  );
}

export default HomePage;
