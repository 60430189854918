import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
} from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const RiskMitigationComponent = ({ riskData }) => {
  return (
    <Card
      sx={{
        backgroundColor: "#1A1E3A",
        color: "#FFFFFF",
        padding: 3,
        marginBottom: 4,
      }}
    >
      <CardContent>
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold", marginBottom: 2, color: "#FFFFFF" }}
        >
          Risk Mitigation Focus
        </Typography>

        <Grid container spacing={4}>
          {riskData?.summary?.map((risk, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Box
                sx={{
                  backgroundColor: "#2D3748",
                  padding: 2,
                  borderRadius: 1,
                }}
              >
                <Typography variant="h6" sx={{ color: "#FFFFFF" }} gutterBottom>
                  {risk.risk}
                </Typography>
                <List>
                  {risk.mitigation_actions.map((action, actionIndex) => (
                    <ListItem key={actionIndex}>
                      <WarningAmberIcon
                        sx={{ color: "#F59E0B", marginRight: 1 }}
                      />
                      <ListItemText
                        primary={action}
                        sx={{
                          "& .MuiListItemText-primary": { color: "#FFFFFF" },
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
                <Typography variant="body2" color="#A0AEC0">
                  <strong>Responsible Team:</strong> {risk.responsible_team}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default RiskMitigationComponent;
