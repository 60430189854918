import { Box, IconButton, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import CustomTextField from "./common/custom-input";
import CustomButton from "./common/custom-loading-button";
import DocumentTable from "./bring-data/document-table";
import UploadSection from "./bring-data/upload-file";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const RAG_BACKEND_URL = process.env.REACT_APP_RAG_BACKEND_URL;

function BringYourData() {
  const [textInput, setTextInput] = useState("");
  const [file, setFile] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchDocuments();
  }, []);

  const fetchDocuments = async () => {
    try {
      const response = await axios.get(`${RAG_BACKEND_URL}/list_documents`);
      setDocuments(response.data.documents || []);
      setLoader(false);
    } catch (err) {
      console.error("Error fetching documents:", err);
      setError("Failed to load documents. Please try again later.");
    }
  };

  const handleTextSubmit = async (e) => {
    e.preventDefault();
    if (!textInput.trim()) return;

    setLoading(true);
    setError(null);
    try {
      await axios.post(`${RAG_BACKEND_URL}/process_document`, {
        text: textInput,
      });
      setTextInput("");
      fetchDocuments();
    } catch (err) {
      console.error("Error processing text:", err);
      setError("Failed to process text. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setLoading(true);
    setError(null);
    const formData = new FormData();
    formData.append("file", file);

    try {
      await axios.post(`${RAG_BACKEND_URL}/upload_file`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setFile(null);
      fetchDocuments();
    } catch (err) {
      console.error("Error uploading file:", err);
      setError("Failed to upload file. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (documentId) => {
    setError(null);
    try {
      await axios.delete(`${RAG_BACKEND_URL}/delete_document/${documentId}`);
      fetchDocuments();
    } catch (err) {
      console.error("Error deleting document:", err);
      setError("Failed to delete document. Please try again.");
    }
  };

  return (
    <Box className="max-w-4xl mx-auto p-4">
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "baseline",
          gap: 2,
        }}
      >
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBack />
        </IconButton>
        <Typography
          variant="h4"
          fontWeight={"bold"}
          gutterBottom
          textAlign="center"
        >
          Bring Your Own Data
        </Typography>
      </Box>

      <Box
        sx={{
          backgroundColor: "#1A1E3A",
          padding: 4,
          borderRadius: 2,
          marginBottom: 4,
          boxShadow: 1,
        }}
      >
        <Typography variant="h6" gutterBottom color="#fff">
          Add Text Data
        </Typography>
        <form onSubmit={handleTextSubmit}>
          <CustomTextField
            multiline
            value={textInput}
            onChange={(e) => setTextInput(e.target.value)}
            placeholder="Enter your data here..."
            variant="outlined"
            fullWidth
            rows={4}
            sx={{ marginBottom: 2 }}
          />
          <CustomButton type="submit" variant="contained" color="primary">
            {loading ? "Processing..." : "Submit Text"}
          </CustomButton>
        </form>
        <UploadSection handleFileUpload={handleFileUpload} />
      </Box>

      <Typography variant="h5" gutterBottom>
        Your Data Entries
      </Typography>
      {error && (
        <Typography color="error" sx={{ marginBottom: 2 }}>
          {error}
        </Typography>
      )}

      <DocumentTable
        documents={documents}
        handleDelete={handleDelete}
        loading={loader}
      />
    </Box>
  );
}

export default BringYourData;
