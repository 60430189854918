import React from "react";
import {
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  Typography,
  LinearProgress,
  Tooltip,
  Box,
} from "@mui/material";
import CustomCircular from "../custom-circular";

const MyStepper = ({ currentStep, totalSteps, stepMessages }) => {
  return (
    <div style={{ width: "100%", padding: "2rem" }}>
      {/* Stepper Component */}
      <Stepper activeStep={currentStep - 1} alternativeLabel>
        {[...Array(totalSteps).keys()].map((step) => (
          <Step key={step}>
            <StepLabel
              StepIconProps={{
                sx: {
                  "&.Mui-completed": {
                    color: "green",
                  },
                  "&.Mui-active": {
                    color: "#1A1E3A",
                  },
                },
              }}
            >
              {`Step ${step + 1}`}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box textAlign={"center"}>
        <CustomCircular />
        <Typography
          variant="subtitle1"
          textAlign={"center"}
          mt={2}
          color="#000"
        >
          {stepMessages[0]}
        </Typography>
      </Box>
      <LinearProgress
        variant="determinate"
        value={(currentStep / totalSteps) * 100}
        style={{
          marginTop: "1rem",
          height: "10px",
          borderRadius: "5px",
          background: "#1A1E3A",
        }}
      />
    </div>
  );
};

export default MyStepper;
