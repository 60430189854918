import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  missionStatement: "",
  projectOverview: {
    narrative: "",
    summary: "",
  },
  keyObjectives: {
    narrative: "",
    summary: [],
  },
  executionPlan: {
    narrative: "",
    summary: [],
  },
  criticalPath: {
    narrative: "",
    summary: [],
  },
  riskMitigationFocus: {
    narrative: "",
    summary: [],
  },
  keyAssumptions: {
    narrative: "",
    summary: [],
  },
  successFactors: {
    narrative: "",
    summary: [],
  },
};

const internalBriefSlice = createSlice({
  name: "internalBrief",
  initialState,
  reducers: {
    setInternalBrief: (state, action) => {
      return { ...action.payload };
    },
    updateInternalBrief: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setInternalBrief, updateInternalBrief } =
  internalBriefSlice.actions;
export default internalBriefSlice.reducer;
