import React from "react";
import { useRoutes } from "react-router-dom";
import AccountSettings from "./components/AccountSettings";
import BringYourData from "./components/BringYourData";
import QuoteDetails from "./components/QuoteDetails";
import PublicLayout from "./layouts/public-layout";
import HomePage from "./pages/home/page";
import QuoteDrafts from "./components/QuoteDrafts";
import ClientQuote from "./pages/quote/client-quote/page";
import InternalBrief from "./pages/quote/internal-brief/page";
import Analysis from "./pages/quote/internal-analysis/page";
// New updates
function AppRoutes() {
  const routes = useRoutes([
    {
      element: <PublicLayout />,
      children: [
        { index: true, element: <HomePage /> },
        { path: "/quote/:quoteId/:version", element: <QuoteDetails /> },
        { path: "/quote/:quoteId/latest", element: <QuoteDetails /> },
        { path: "/data", element: <BringYourData /> },
        { path: "/quote/client-quote", element: <ClientQuote /> },
        { path: "/quote/internal-brief", element: <InternalBrief /> },
        { path: "/quote/internal-analysis", element: <Analysis /> },
        { path: "/settings", element: <AccountSettings /> },
        { path: "/drafts", element: <QuoteDrafts /> },
        { path: "*", element: {} },
      ],
    },
  ]);

  return routes;
}

export default AppRoutes;
