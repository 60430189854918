import React from "react";
import { Box, Typography, Button } from "@mui/material";

const UploadSection = ({ handleFileUpload }) => {
  return (
    <Box
      sx={{
        backgroundColor: "#171D31",
        padding: 4,
        borderRadius: 2,
        marginTop: 2,
      }}
    >
      <Typography variant="h6" color="#fff" gutterBottom>
        Upload File
      </Typography>
      <input
        type="file"
        onChange={handleFileUpload}
        style={{ display: "none" }}
        id="upload-file-input"
      />
      <label htmlFor="upload-file-input">
        <Button
          variant="outlined"
          component="span"
          sx={{
            color: "#fff",
            borderColor: "#777",
            padding: "0.5rem 1rem",
            borderRadius: "0.25rem",
            marginBottom: 2,
            "&:hover": {
              borderColor: "#fff",
            },
          }}
        >
          Choose File
        </Button>
      </label>
      <Typography variant="body2" color="#777">
        No file chosen
      </Typography>
    </Box>
  );
};

export default UploadSection;
