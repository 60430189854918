import React from "react";
import { Button } from "@mui/material";

const CustomButton = ({
  loading = false,
  children,
  onClick,
  type = "button",
  variant = "contained",
  color = "secondary",
  sx = {},
  ...props
}) => {
  return (
    <Button
      variant={variant}
      color={color}
      type={type}
      onClick={onClick}
      disabled={loading}
      sx={{
        backgroundColor: "white",
        color: "#000",
        padding: "0.75rem 1rem",
        borderRadius: "0.25rem",
        fontWeight: "bold",
        width: "200px",
        alignContent: "center",
        ...sx,
      }}
      {...props}
    >
      {loading ? "Generating..." : children}
    </Button>
  );
};

export default CustomButton;
