import { ArrowBack } from "@mui/icons-material";
import { Box, Button, IconButton } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Clientquote from "../../../components/quote/client-quote";
import { downloadPDF } from "../../../function";

export default function ClientQuote() {
  const clientQuote = useSelector((state) => state.clientQuote);
  const navigate = useNavigate();

  return (
    <Box className="max-w-7xl mx-auto p-6">
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        px={2}
      >
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBack />
        </IconButton>
        <Button
          onClick={() => downloadPDF("quote-content", "client")}
          sx={{
            background: "#171D31",
            color: "#fff",
          }}
        >
          Download
        </Button>
      </Box>
      <Clientquote quote={clientQuote} id="quote-content" />
    </Box>
  );
}
