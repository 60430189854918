import { AccessTime } from "@mui/icons-material";
import { Box, Button, Chip, Divider, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import CustomButton from "../../common/custom-button";

export default function QuoteHeader({
  title,
  quoteVersion,
  created_at,
  language,
}) {
  return (
    <Box
      sx={{
        background: "#171D31",
        padding: "20px",
        borderRadius: "8px",
        color: "#fff",
        mb: 1,
      }}
    >
      <Typography
        variant="h5"
        sx={{
          fontWeight: "bold",
          textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
          color: "#fff",
        }}
      >
        {language === "en" ? title : JSON.parse(title)?.translated_title}
      </Typography>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={2}
      >
        <Chip
          sx={{
            background: "#fff",
          }}
          label={
            <Typography variant="body1" color="#000">
              Version: {quoteVersion}
            </Typography>
          }
        />
        <CustomButton
          variant="contained"
          color="primary"
          sx={{
            marginTop: "16px",
            background: "#fff",
            textTransform: "none",
            color: "#000",
            fontWeight: "bold",
          }}
        >
          Download Quote
        </CustomButton>
      </Box>

      <Divider
        sx={{ margin: "16px 0", backgroundColor: "#fff", opacity: 0.6 }}
      />

      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <AccessTime sx={{ marginRight: 1, color: "#fff" }} />
        <Typography variant="body2" sx={{ color: "#fff" }}>
          Created At: {moment(created_at)?.fromNow()}
        </Typography>
      </Box>
    </Box>
  );
}
