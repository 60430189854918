import { Box, Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchQuotes } from "../../../api";
import CustomButton from "../../common/custom-button";
import CustomCircular from "../../common/custom-circular";
import DataTable from "../../common/custom-table";

export default function QuoteTable() {
  const [quotes, setQuotes] = useState([]);
  const [loading, setLoading] = useState(true); // Added loading state
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 7;

  const navigate = useNavigate();

  useEffect(() => {
    const loadQuotes = async () => {
      try {
        const fetchedQuotes = await fetchQuotes();
        setQuotes(fetchedQuotes);
      } catch (error) {
        setError("Failed to load quotes. Please try again later.");
      } finally {
        setLoading(false); // Stop loading after fetching is complete
      }
    };

    loadQuotes();
  }, []);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const displayedQuotes = quotes.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const columns = [
    {
      field: "title",
      headerName: "Title",
      render: (row) => row.title || "Untitled Quote",
    },
    {
      field: "overview",
      headerName: "Overview",
      render: (row) =>
        row.client_quote?.customer_facing?.overview_summary ||
        row.client_quote?.customer_facing?.overview ||
        "No description available",
    },
    {
      field: "created_at",
      headerName: "Created Date",
      render: (row) => new Date(row.created_at).toLocaleDateString(),
    },
    {
      field: "Action",
      headerName: "Action",
      render: (row) => {
        return (
          <CustomButton
            variant="contained"
            sx={{
              background: "#171D31",
            }}
            onClick={() => navigate(`/quote/${row?.quote_id}/${row.version}`)}
          >
            View
          </CustomButton>
        );
      },
    },
  ];

  return (
    <div>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="300px"
        >
          <CustomCircular />
        </Box>
      ) : quotes.length === 0 ? (
        <Box>
          <p>No quotes available.</p>
        </Box>
      ) : (
        <>
          <DataTable rows={displayedQuotes} columns={columns} />
          <Pagination
            count={Math.ceil(quotes.length / rowsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            color="primary"
            style={{
              marginTop: "1rem",
              display: "flex",
              justifyContent: "center",
            }}
          />
        </>
      )}
    </div>
  );
}
