import { ArrowBack } from "@mui/icons-material";
import { Box, Button, IconButton } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import InternalBriefComponent from "../../../components/quote/internal-brief";
import { downloadPDF } from "../../../function";

export default function InternalBrief() {
  const internal_brief = useSelector((state) => state.internalBrief);
  const navigate = useNavigate();
  useEffect(() => {
    if (internal_brief?.missionStatement === "") {
      navigate(-1);
    }
  }, [internal_brief, navigate]);

  return (
    <Box className="max-w-7xl mx-auto p-6">
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        px={2}
      >
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBack />
        </IconButton>
        <Button
          onClick={() => downloadPDF("brief-content", "client")}
          sx={{
            background: "#171D31",
            color: "#fff",
          }}
        >
          Download
        </Button>
      </Box>
      {/* Render the component only if internal_brief is not null */}
      {internal_brief && (
        <InternalBriefComponent
          briefData={internal_brief}
          id={"brief-content"}
        />
      )}
    </Box>
  );
}
