import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import clientQuoteSlice from "./slice/clientQuoteSlice";
import internalAnalysis from "./slice/internal-analysis";
import internalBrief from "./slice/internal-brief";

// Combine all slice reducers into a root reducer
const rootReducer = combineReducers({
  clientQuote: clientQuoteSlice,
  internalAnalysis: internalAnalysis,
  internalBrief: internalBrief,
});

// Configuration for redux-persist
const persistConfig = {
  key: "root",
  storage,
};

// Wrap root reducer with persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the store with the persisted reducer
const store = configureStore({
  reducer: persistedReducer,
});

// Create persistor to persist the store
const persistor = persistStore(store);

export { store, persistor };
