import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  Box,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const KeyAssumptionsAndSuccessFactors = ({ briefData }) => {
  return (
    <Box sx={{ marginBottom: 4 }}>
      {/* Key Assumptions */}
      <Card
        sx={{
          backgroundColor: "#1A1E3A",
          color: "#FFFFFF",
          padding: 3,
          marginBottom: 4,
        }}
      >
        <CardContent>
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold", marginBottom: 2, color: "#FFFFFF" }}
          >
            Key Assumptions
          </Typography>
          <Grid container spacing={4}>
            {briefData.key_assumptions.summary.map((assumption, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Box sx={{ padding: 2 }}>
                  <Typography variant="body1" sx={{ color: "#FFFFFF" }}>
                    {assumption.assumption}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: "#A0AEC0", marginTop: 1 }}
                  >
                    <strong>Validation Method:</strong>{" "}
                    {assumption.validation_method}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: "#A0AEC0", marginTop: 1 }}
                  >
                    <strong>Responsible Team:</strong>{" "}
                    {assumption.responsible_team}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>

      {/* Success Factors */}
      <Card
        sx={{
          backgroundColor: "#1A1E3A",
          color: "#FFFFFF",
          padding: 3,
        }}
      >
        <CardContent>
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold", marginBottom: 2, color: "#FFFFFF" }}
          >
            Success Factors
          </Typography>
          <List>
            {briefData.success_factors.summary.map((factor, index) => (
              <ListItem key={index}>
                <CheckCircleIcon sx={{ color: "#10B981", marginRight: 1 }} />
                <ListItemText
                  primary={factor}
                  sx={{
                    "& .MuiListItemText-primary": {
                      color: "#FFFFFF",
                    },
                  }}
                />
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
    </Box>
  );
};

export default KeyAssumptionsAndSuccessFactors;
