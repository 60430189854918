import {
  Box,
  Card,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

const PricingSection = ({ pricing }) => {
  console.log(pricing);
  return (
    <Box sx={{ maxWidth: 1200, mx: "auto", my: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Pricing
      </Typography>

      <Card
        sx={{
          p: 3,
          backgroundColor: "#1A1E3A",
          color: "#fff",
          borderRadius: 2,
          boxShadow: 4,
        }}
      >
        {/* Labor Costs Section */}
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold", mb: 2, color: "#fff" }}
          >
            Labor Costs
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: "#A0AEC0" }}>Outcome</TableCell>
                <TableCell sx={{ color: "#A0AEC0" }}>Description</TableCell>
                <TableCell sx={{ color: "#A0AEC0" }}>Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pricing.labor_costs?.map((cost, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ color: "#A0AEC0" }}>
                    {cost?.outcome}
                  </TableCell>
                  <TableCell sx={{ color: "#A0AEC0" }}>
                    {cost?.description}
                  </TableCell>
                  <TableCell sx={{ color: "#A0AEC0" }}>{cost?.total}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Typography
            variant="body1"
            sx={{ fontWeight: "bold", color: "#fff", mt: 2 }}
          >
            Total Labor Cost: {pricing.total_labor_cost}
          </Typography>
        </Box>

        {/* Material Costs Section */}
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold", mb: 2, color: "#fff" }}
          >
            Material Costs
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: "#A0AEC0" }}>Item</TableCell>
                <TableCell sx={{ color: "#A0AEC0" }}>Description</TableCell>
                <TableCell sx={{ color: "#A0AEC0" }}>Quantity</TableCell>
                <TableCell sx={{ color: "#A0AEC0" }}>Unit Price</TableCell>
                <TableCell sx={{ color: "#A0AEC0" }}>Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pricing.material_costs?.map((material, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ color: "#A0AEC0" }}>
                    {material.item}
                  </TableCell>
                  <TableCell sx={{ color: "#A0AEC0" }}>
                    {material?.description}
                  </TableCell>
                  <TableCell sx={{ color: "#A0AEC0" }}>
                    {material.quantity}
                  </TableCell>
                  <TableCell sx={{ color: "#A0AEC0" }}>
                    {material.unit_price}
                  </TableCell>
                  <TableCell sx={{ color: "#A0AEC0" }}>
                    {material.total}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Typography
            variant="body1"
            sx={{ fontWeight: "bold", color: "#fff", mt: 2 }}
          >
            Total Material Cost: {pricing.total_material_cost}
          </Typography>
        </Box>

        {/* Equipment Costs Section */}
        {pricing?.equipment_costs?.length > 0 && (
          <Box sx={{ mb: 4 }}>
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", mb: 2, color: "#fff" }}
            >
              Equipment Costs
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ color: "#A0AEC0" }}>Item</TableCell>
                  <TableCell sx={{ color: "#A0AEC0" }}>Description</TableCell>
                  <TableCell sx={{ color: "#A0AEC0" }}>Duration</TableCell>
                  <TableCell sx={{ color: "#A0AEC0" }}>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pricing.equipment_costs?.map((equipment, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ color: "#A0AEC0" }}>
                      {equipment.item}
                    </TableCell>
                    <TableCell sx={{ color: "#A0AEC0" }}>
                      {equipment.description}
                    </TableCell>
                    <TableCell sx={{ color: "#A0AEC0" }}>
                      {equipment.duration}
                    </TableCell>
                    <TableCell sx={{ color: "#A0AEC0" }}>
                      {equipment.total}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", color: "#fff", mt: 2 }}
            >
              Total Equipment Cost: {pricing.total_equipment_cost}
            </Typography>
          </Box>
        )}

        {/* Total Price Section */}
        <Box sx={{ mt: 4, textAlign: "center" }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", color: "#fff" }}>
            Total Price:{" "}
            <span style={{ color: "#10B981" }}>{pricing?.total_price}</span>
          </Typography>
        </Box>
      </Card>
    </Box>
  );
};

export default PricingSection;
