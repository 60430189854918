import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

// Create the slice
const projectOverviewSlice = createSlice({
  name: "projectOverview",
  initialState,
  reducers: {
    setProjectOverview: (state, action) => {
      return { ...action.payload };
    },
    updateProjectOverview: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setProjectOverview, updateProjectOverview } =
  projectOverviewSlice.actions;
export default projectOverviewSlice.reducer;
