import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const ExecutionPlanComponent = ({ executionPlan }) => {
  return (
    <Card
      sx={{
        backgroundColor: "#1A1E3A",
        color: "#FFFFFF",
        padding: 3,
        marginBottom: 4,
      }}
    >
      <CardContent>
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold", marginBottom: 2, color: "#FFFFFF" }}
        >
          Execution Plan
        </Typography>

        <Grid container spacing={4}>
          {executionPlan.summary.map((phase, index) => (
            <Grid item xs={12} sm={6} key={index} sx={{ display: "flex" }}>
              <Box
                sx={{
                  backgroundColor: "#2D3748",
                  padding: 2,
                  borderRadius: 1,
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                <Typography variant="h6" color="#FFFFFF" gutterBottom>
                  {phase.phase}
                </Typography>
                <List>
                  {phase.key_actions.map((action, actionIndex) => (
                    <ListItem key={actionIndex}>
                      <CheckCircleIcon
                        sx={{ color: "#10B981", marginRight: 1 }}
                      />
                      <ListItemText
                        primary={action}
                        sx={{
                          "& .MuiListItemText-primary": { color: "#FFFFFF" },
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
                <Typography variant="body2" color="#A0AEC0">
                  <strong>Timeline:</strong> {phase.timeline}
                </Typography>
                <Typography variant="body2" color="#A0AEC0">
                  <strong>Responsible Team:</strong> {phase.responsible_team}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ExecutionPlanComponent;
