import { AppBar, Box } from "@mui/material";
import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";

export default function PublicLayout() {
  return (
    <Box>
      <Box>
        <Suspense>
          <Outlet />
        </Suspense>
      </Box>
    </Box>
  );
}
