import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

const UpSellOpportunities = ({ analysis }) => {
  return (
    <Box>
      <Card
        variant="outlined"
        sx={{ marginBottom: 2, backgroundColor: "#1A1E3A", color: "#FFFFFF" }}
      >
        <CardContent>
          <Typography variant="h5" sx={{ fontWeight: "bold" }} gutterBottom>
            Upsell Opportunities
          </Typography>
          <Typography sx={{ color: "#A0AEC0", mb: 2 }}>
            {analysis.upsell_opportunities.narrative}
          </Typography>

          <List>
            {analysis.upsell_opportunities.summary.map((opportunity, index) => (
              <ListItem key={index} sx={{ mb: 2 }}>
                {/* Left Section (Icon and Opportunity Details) */}
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={8}>
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: "bold", color: "#FFFFFF" }}
                    >
                      {opportunity.opportunity}
                    </Typography>
                    <Typography variant="body2" sx={{ color: "#A0AEC0" }}>
                      Valor Estimado: {opportunity.estimated_value}, Beneficio
                      para el Cliente: {opportunity.client_benefit}
                    </Typography>
                  </Grid>

                  {/* Right Section (Implementation Ease) */}
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    textAlign={{ xs: "left", sm: "right" }}
                  >
                    <Typography variant="body2" sx={{ color: "#F59E0B" }}>
                      {opportunity.implementation_ease === "Easy" && (
                        <CheckCircleIcon sx={{ mr: 1 }} />
                      )}
                      {opportunity.implementation_ease === "Moderate" && (
                        <TrendingUpIcon sx={{ mr: 1 }} />
                      )}
                      {opportunity.implementation_ease}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ mt: 2, backgroundColor: "#2D3748" }} />
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
    </Box>
  );
};

export default UpSellOpportunities;
