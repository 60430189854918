import { Box, Divider, List, ListItem, ListItemText, Typography } from "@mui/material";

const AssumptionsAndCriteria = ({ assumptions, acceptance_criteria }) => {
  return (
    <Box sx={{ mt: 4, px: 3 }}>
      {/* Assumptions Section */}
      <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
        Assumptions
      </Typography>
      <List disablePadding>
        {assumptions?.map((assumption, index) => (
          <ListItem key={index} sx={{ py: 0.5 }}>
            <ListItemText
              primary={
                <Typography variant="body1" sx={{ lineHeight: "1.6" }}>
                  {assumption}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </List>

      {/* Divider to separate sections */}
      <Divider sx={{ my: 3 }} />

      {/* Acceptance Criteria Section */}
      <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
        Acceptance Criteria
      </Typography>
      <List disablePadding>
        {acceptance_criteria?.map((criteria, index) => (
          <ListItem key={index} sx={{ py: 0.5 }}>
            <ListItemText
              primary={
                <Typography variant="body1" sx={{ lineHeight: "1.6" }}>
                  {criteria}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default AssumptionsAndCriteria;
