import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Divider,
  Box,
  List,
  ListItem,
  ListItemText,
  Grid,
} from "@mui/material";
import {
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Chart as ChartJS,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ResourceAnalysisComponent from "./resource-analyst";
import UpSellOpportunities from "./up-sell";
import CustomDivider from "../../common/custom-divider";

// Register necessary chart components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// Reusable Section Component
const AnalysisSection = ({ title, children }) => (
  <Card
    variant="outlined"
    sx={{ marginBottom: 2, backgroundColor: "#1A1E3A", color: "#FFFFFF" }}
  >
    <CardContent>
      <Typography variant="h5" sx={{ fontWeight: "bold" }} gutterBottom>
        {title}
      </Typography>
      {children}
    </CardContent>
  </Card>
);

// Reusable List Component
const AnalysisList = ({ items }) => (
  <List>
    {items.map((item, index) => (
      <ListItem key={index}>
        <ListItemText
          primary={item.primary}
          secondary={item.secondary}
          sx={{
            "& .MuiListItemText-primary": { color: "#fff" },
            "& .MuiListItemText-secondary": { color: "#777" },
          }}
        />
      </ListItem>
    ))}
  </List>
);

const AnalysisDivider = () => <CustomDivider />;

const AnalysisComponent = ({ analysis, id }) => {
  const marginChartData = {
    labels: analysis.margin_analysis.summary.margin_by_component.map(
      (c) => c.component
    ),
    datasets: [
      {
        label: "Revenue",
        data: analysis.margin_analysis.summary.margin_by_component.map((c) =>
          parseFloat(c.revenue.replace("$", ""))
        ),
        backgroundColor: "#10B981", // Light green for revenue
      },
      {
        label: "Cost",
        data: analysis.margin_analysis.summary.margin_by_component.map((c) =>
          parseFloat(c.cost.replace("$", ""))
        ),
        backgroundColor: "#EF4444",
      },
    ],
  };

  const profitDrivers = analysis.margin_analysis.summary.key_profit_drivers.map(
    (driver) => ({
      primary: driver.driver,
      secondary: `Impact: ${driver.impact}, Sensitivity: ${driver.sensitivity}`,
    })
  );

  const breakEvenAnalysis = [
    {
      primary: "Break Even Point",
      secondary:
        analysis.margin_analysis.summary.break_even_analysis.break_even_point,
    },
    {
      primary: "Safety Margin",
      secondary:
        analysis.margin_analysis.summary.break_even_analysis.safety_margin,
    },
  ];

  const marginComponents =
    analysis.margin_analysis.summary.margin_by_component.map((component) => ({
      primary: component.component,
      secondary: `Revenue: ${component.revenue}, Cost: ${component.cost}, Margin: ${component.margin.amount} (${component.margin.percentage})`,
    }));

  const riskItems = analysis.risk_assessment.summary.map((risk) => ({
    primary: risk.risk,
    secondary: `Impact: ${risk.impact}, Probability: ${risk.probability}, Financial Impact: ${risk.financial_impact}`,
    strategy: risk.mitigation_strategy,
  }));

  return (
    <Box padding={3} id={id}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 3 }}
      >
        <Typography variant="h4" gutterBottom color="#1A1E3A">
          Internal Analysis
        </Typography>
      </Grid>

      {/* Executive Summary */}
      <AnalysisSection title="Executive Summary">
        <Typography>{analysis.executive_summary}</Typography>
      </AnalysisSection>

      {/* Margin Analysis */}
      <AnalysisSection title="Margin Analysis">
        <Typography>{analysis.margin_analysis.narrative}</Typography>
        <AnalysisDivider />
        <Bar
          data={marginChartData}
          options={{
            responsive: true,
            plugins: {
              legend: { position: "top", labels: { color: "#FFFFFF" } },
              title: {
                display: true,
                text: "Revenue vs Costs by Component",
                color: "#FFFFFF",
              },
            },
            scales: {
              x: {
                ticks: { color: "#FFFFFF" },
                grid: { color: "#2D3748" },
              },
              y: {
                ticks: { color: "#FFFFFF" },
                grid: { color: "#2D3748" },
              },
            },
          }}
        />
        <AnalysisDivider />
        <Typography variant="h6">Overall Margin Summary</Typography>
        <AnalysisList items={marginComponents} />
        <AnalysisDivider />
        <Typography variant="h6" sx={{ fontWeight: "bold", color: "#FFFFFF" }}>
          Key Profit Drivers
        </Typography>
        <AnalysisList items={profitDrivers} />
        <AnalysisDivider />
        <Typography variant="h6" sx={{ fontWeight: "bold", color: "#FFFFFF" }}>
          Break-Even Analysis
        </Typography>
        <AnalysisList items={breakEvenAnalysis} />
      </AnalysisSection>

      {/* Risk Assessment */}
      <AnalysisSection title="Risk Assessment">
        <Typography>{analysis.risk_assessment.narrative}</Typography>
        <List>
          {riskItems.map((risk, index) => (
            <ListItem key={index}>
              <ListItemText
                primary={risk.primary}
                secondary={risk.secondary}
                sx={{
                  "& .MuiListItemText-primary": { color: "#fff" },
                  "& .MuiListItemText-secondary": { color: "#777" },
                }}
              />
              <Typography variant="caption" sx={{ color: "#777" }}>
                {risk.strategy}
              </Typography>
            </ListItem>
          ))}
        </List>
      </AnalysisSection>

      {/* Additional Components */}
      <UpSellOpportunities analysis={analysis} />
      <ResourceAnalysisComponent analysis={analysis} />
    </Box>
  );
};

export default AnalysisComponent;
